@import './stylevars.module.scss';

.defaultTheme {
  /* Format: --theme + type + color + variant */

  /* Colors */
  --theme-color-primary: var(--color-white);
  --theme-color-inverse-primary: var(--color-black);
  --theme-color-accent: var(--color-orange400);
  --theme-color-negative: var(--color-red400);
  --theme-color-positive: var(--color-green400);
  --theme-color-warning: var(--color-yellow400);

  /* Background */
  --theme-background-color-primary: var(--color-grey900);
  --theme-background-color-secondary: var(--color-grey800);
  --theme-background-color-tertiary: var(--color-white);
  --theme-background-color-inverse-primary: var(--color-black);
  --theme-background-color-inverse-secondary: var(--color-grey100);
  --theme-background-color-inverse-tertiary: var(--color-grey200);
  --theme-background-color-disabled: var(--color-grey800);
  --theme-background-color-disabled-dark: var(--color-grey700);
  --theme-background-color-overlay-dark: var(--color-overlay-dark-100);
  --theme-background-color-overlay-light: var(--color-overlay-light-50);
  --theme-background-color-accent: var(--color-orange400);
  --theme-background-color-negative: var(--color-red400);
  --theme-background-color-warning: var(--color-yellow400);
  --theme-background-blue-color: var(--color-cyan100);
  --theme-background-color-positive: var(--color-green400);
  --theme-background-color-accent-light: var(--color-orange700);
  --theme-background-color-negative-light: var(--color-red700);
  --theme-background-color-negative-secondary: var(--color-red800);
  --theme-background-color-warning-light: var(--color-yellow700);
  --theme-background-color-positive-light: var(--color-green700);
  --theme-background-color-accent-dark: var(--color-orange300);
  --theme-background-color-negative-dark: var(--color-red300);
  --theme-background-color-warning-dark: var(--color-yellow300);
  --theme-background-color-positive-dark: var(--color-green300);
  --theme-background-color-primary-overlay-dark: var(--color-overlay-dark-100);

  /* Typograph */
  --theme-text-color-primary: var(--color-black);
  --theme-text-primary-color-disabled: var(--color-grey700);
  --theme-text-color-secondary: var(--color-grey300);
  --theme-text-color-tertiary: var(--color-grey400);
  --theme-text-color-inverse-primary: var(--color-white);
  --theme-text-color-inverse-secondary: var(--color-grey600);
  --theme-text-color-inverse-tertiary: var(--color-grey500);
  --theme-text-color-disabled: var(--color-grey500);
  --theme-text-color-positive-dark: var(--color-green300);
  --theme-text-color-blue: var(--color-cyan400);
  --theme-text-color-onbackground: var(--color-white);
  --theme-text-color-accent: var(--color-orange400);
  --theme-text-color-negative: var(--color-red400);
  --them-text-color-negative-light: var(--color-orange200);
  --theme-text-color-negative-secondary: var(--color-red500);
  --theme-text-color-positive: var(--color-yellow400);
  --theme-text-color-warning: var(--color-green400);

  /* Border */
  --theme-border-color-primary: var(--color-grey700);
  --theme-border-color-primary-light: var(--color-grey800);
  --theme-border-color-transparent: var(--color-overlay-dark-50);
  --theme-border-color-highlight: var(--color-black);
  --theme-border-color-inverse-primary: var(--color-grey200);
  --theme-border-color-inverse-transparent: var(--color-overlay-light-100);
  --theme-border-color-inverse-highlight: var(--color-white);
  --theme-border-color-disabled: var(--color-grey700);
  --theme-border-color-accent: var(--color-orange400);
  --theme-border-color-accent-semi-light: var(--color-orange600);
  --theme-border-color-accent-light: var(--color-orange700);
  --theme-border-color-negative: var(--color-red400);
  --theme-border-color-negative-light: var(--color-red700);
  --theme-border-color-warning: var(--color-yellow600);
  --theme-border-color-positive: var(--color-green600);
  --theme-border-color-light-divider: var(--color-grey900);
  --theme-border-color-check: var(--color-grey600);

  /* ConversationItem */
  --theme-converstation-item-border-color: var(--color-grey800);

  /* Tabs */
  --theme-tabs-color-inactive: var(--color-grey500);
  --theme-tabs-background-color-hover: var(--color-grey700);

  /* Badge */
  --theme-badge-background-color-tertiary: var(--color-grey500);
}

.darkTheme {
  /* Colors */
  --theme-color-primary: var(--color-grey700);
  --theme-color-inverse-primary: var(--color-grey50);
  --theme-color-accent: var(--color-orange400);
  --theme-color-negative: var(--color-red500);
  --theme-color-positive: var(--color-green500);
  --theme-color-warning: var(--color-yellow500);
}

.defaultTheme,
.darkTheme {
  height: 100%;
}
